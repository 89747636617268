import {
    handleQueryResolve,
} from '../utils';
import {buildMapToField} from "../../kroll/utils";
// PatientID, GenericName = '', Strength = '', extendedWhere = 'rx.RxDate > DATEADD(month, -6, GetDate())'
export default function () {
// FIXME - need to set this up to work with the query for the blister pack 
   

        return this.query(`
        SELECT 
GroupID
      ,sParameterName as Name
  FROM [Fillware].[dbo].[SystemParameters]
  INNER JOIN [Fillware].[dbo].[PatientGroup] ON [Fillware].[dbo].[SystemParameters].[nID] = Fillware.dbo.PatientGroup.GroupID
  GROUP BY GroupID, sParameterName
        `).then(handleQueryResolve);
    
}